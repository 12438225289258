import { render, staticRenderFns } from "./SideWrapper.vue?vue&type=template&id=df7c07e6&scoped=true&"
import script from "./SideWrapper.vue?vue&type=script&lang=js&"
export * from "./SideWrapper.vue?vue&type=script&lang=js&"
import style0 from "./SideWrapper.vue?vue&type=style&index=0&id=df7c07e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df7c07e6",
  null
  
)

export default component.exports
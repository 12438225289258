<template>
  <div class="user-side-card">

    <BlockPerson
      class="avatar-block"
      :person="currentUser"
      :hide-rate="!user.helper_mode"
    />

    <router-link :to="editRoute" class="user-side-card__redact">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 14.25L11.063 3.187l3.75 3.75L3.75 18H0v-3.75zM17.719 4.031L15.89 5.86l-3.75-3.75L13.969.281A.954.954 0 0114.672 0c.281 0 .515.094.703.281l2.344 2.344a.954.954 0 01.281.703.954.954 0 01-.281.703z" fill="#0C76DA"/></svg>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BlockPerson from '@component/Module/BlockPerson';
import BlockPersonModel from '@component/Module/BlockPersonModel.js';

export default {
  components: {
    BlockPerson,
  },
  props: {
    editRoute: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    currentUser() {
      return new BlockPersonModel({
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        photo: this.user.photo,
        rate: this.user.rate_avg,
      });
    },
  },
};
</script>

<style lang="scss" scoped>



// user-side-card
.user-side-card {
  display: flex;
  align-items: center;
  padding: 25px 14px 20px 14px;
  border-bottom: 1px solid rgba(#000000, 0.2);
  .user-side-card__redact {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: var(--color-prime);
    transition: all .2s ease-in-out;
    cursor: pointer;
    svg {
      path {
        fill: #FFFFFF;
      }
    }
    &:hover {
      background-color: var(--color-prime-active);
    }
  }
  @include respond-to('sm') {
    padding: 35px 20px 30px 18px;
    .user-side-card__redact {
      width: 42px;
      height: 42px;
    }
  }
}



.avatar-block{
  margin-right: auto;
}

</style>

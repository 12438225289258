<template>
  <div
    :class="['side-panel__collapse', { 'side-panel__collapse--open': collapse === true }]"
    @click="collapse = !collapse"
  >
    <div class="side-panel__collapse-head">
      {{collapse ? 'Свернуть подробную информацию' : 'Показать подробную информацию'}}
      </div>
    <div v-show="collapse" class="side-panel__collapse-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collapse: false,
    };
  },
};
</script>

<style lang="scss" scoped>

// side-panel
.side-panel__collapse {
  border-bottom: 1px solid rgba(#000000, 0.2);
  .side-panel__collapse-head {
    margin-bottom: 0;
    padding: 14px 14px;
    color: var(--color-prime);
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    opacity: 0.9;// ?????

  }
  .side-panel__collapse-body {
    padding: 4px 14px 10px;
  }
}

@include respond-to('sm') {

  .side-panel__collapse {
    .side-panel__collapse-head {
      margin-bottom: 6px;
      padding: 18px 16px;
    }
    .side-panel__collapse-body {
      padding: 4px 16px 14px;
    }
  }
}



</style>

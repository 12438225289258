<template>
  <div class="side-panel" >
    <div class="side-panel__body">
      <slot></slot>
    </div>
    <div class="side-panel__foot">
      <div @click="exit" class="btn-logout">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.669 2.43a.833.833 0 00-.667 1.527 7.501 7.501 0 01-3 14.376 7.503 7.503 0 01-3-14.377.833.833 0 10-.667-1.526 9.17 9.17 0 00-5.5 8.403A9.167 9.167 0 1013.67 2.43z" fill="#B3B3B8"/><path d="M10.001 9.167c.46 0 .834-.373.834-.834v-7.5a.833.833 0 10-1.667 0v7.5c0 .46.373.834.833.834z" fill="#B3B3B8"/></svg>
        <span>Выйти</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('auth', ['logout']),
    exit() {
      this.$modal.show('Confirm', {
        title: 'Вы уверены, что хотите выйти?'
      })
        .then( async res => {
          if (!res) return;
          await this.logout();
          await this.$router.go();
        });
    },
  }
};
</script>

<style lang="scss" scoped>

// side-panel
.side-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 52px);
  .side-panel__body {
    margin-bottom: 50px;
  }
  .side-panel__foot {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }

  @include respond-to('sm') {
    .side-panel__body {
      margin-bottom: 60px;
    }

  }
}






// btn-logout
.btn-logout {
  display: flex;
  align-items: center;
  margin: 0 auto;
  color: #B3B3B8;
  font-size: 17px;
  line-height: 1;
  cursor: pointer;
  transition: color .2s ease-in-out;
  span {
    margin-left: 10px;
  }
  svg {
    width: 18px;
    height: auto;
    path {
      fill: #B3B3B8;
      transition: fill .2s ease-in-out;
    }
  }
  &:hover {
    color: #000000;
    svg {
      path {
        fill: #000000;
      }
    }
  }
  @include respond-to('sm') {
    font-size: 19px;
    svg {
      width: 20px;
    }
  }
}


</style>
